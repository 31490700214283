const ROLES = [
  { id: 1, name: 'BoysTown Admin', isAdmin: true },
  { id: 5, name: 'Content Reviewer', isAdmin: false },
  { id: 2, name: 'District Admin', isAdmin: true },
  { id: 3, name: 'School Admin', isAdmin: true },
  { id: 4, name: 'Learner', isAdmin: false },
];

const ROLE_CONST = {
  BoysTownAdmin: 1,
  DistrictAdmin: 2,
  SchoolAdmin: 3,
  Learner: 4,
  ContentReviewer: 5,
}

const isAdmin = (sessionOrToken) => {
  if (sessionOrToken?.user?.hasOwnProperty("role")) {
    return sessionOrToken.user.role <= ROLE_CONST.SchoolAdmin;
  } else if (sessionOrToken?.hasOwnProperty('user_role')) {
    return sessionOrToken.user_role <= ROLE_CONST.SchoolAdmin;
  }

  return false;
};

const isBoysTownAdmin = (sessionOrToken) => {
  if (sessionOrToken?.user?.hasOwnProperty("role")) {
    return sessionOrToken.user.role === ROLE_CONST.BoysTownAdmin;
  } else if (sessionOrToken?.hasOwnProperty('user_role')) {
    return sessionOrToken?.user_role == ROLE_CONST.BoysTownAdmin;
  }

  return false;
};

const isContentReviewer = (sessionOrToken) => {
  if (sessionOrToken?.user?.hasOwnProperty("role")) {
    return sessionOrToken.user.role === ROLE_CONST.ContentReviewer;
  } else if (sessionOrToken?.hasOwnProperty('user_role')) {
    return sessionOrToken?.user_role == ROLE_CONST.ContentReviewer;
  }

  return false;
};

export { ROLES, isAdmin, isBoysTownAdmin, isContentReviewer, ROLE_CONST };
