import { isBoysTownAdmin, isContentReviewer } from './roleUtils';
import { SUBSCRIPTION_TYPES } from './constants';
import { generateUUID } from './stringUtils';
import { ROLE_CONST } from './roleUtils';

export const hasModuleAccess = (session, product): boolean => {
  // not logged in, no access
  if (!session?.user) {
    return false;
  }

  // Boys Town admin have access
  if (isBoysTownAdmin(session)) {
    return true;
  }

  // Content Reviewer have access
  if (isContentReviewer(session)) {
    return true;
  }

  if (session?.user?.subscriptions) {
    return session.user.subscriptions.find((s) => s.product === product && s.is_active) != null;
  }

  // legacy logic
  // no subscription - no access
  if (!session?.user?.subscription || session.user.subscription?.is_active !== true) {
    return false;
  }

  // valid subscription
  if (session?.user?.subscription?.type === SUBSCRIPTION_TYPES.FreeTrial || session?.user?.subscription?.type === SUBSCRIPTION_TYPES.Individual) {
    return true;
  }

  // no
  return false;
};

export const hasFreeContentAccess = (session): boolean => {
  // not logged in, no access
  if (!session?.user) {
    return false;
  }

  // Boys Town admin have access
  if (isBoysTownAdmin(session)) {
    return true;
  }

  // Content Reviewer have access
  if (isContentReviewer(session)) {
    return true;
  }

  if (session?.user?.subscriptions) {
    return session.user.subscriptions.find((s) => s.product === 'free' && s.is_active) != null;
  }

  // ----- legacy ------
  // no subscription - no access
  if (!session?.user?.subcription || !session?.user?.subcription?.is_active) {
    return false;
  }

  // valid subscription
  if (
    session?.user?.subcription?.type === SUBSCRIPTION_TYPES.FreeTrial ||
    session?.user?.subcription?.type === SUBSCRIPTION_TYPES.Free ||
    session?.user?.subcription?.type === SUBSCRIPTION_TYPES.Individual
  ) {
    return true;
  }

  // no
  return false;
};

export const getFreeSubscriptionObject = () => {
  return {
    product: 'free',
    status: 'active',
    start_date: Date.now(),
    end_date: new Date(new Date().setFullYear(new Date().getFullYear() + 99)).getTime(),
    id: generateUUID(),
  };
};

export const isCustomer = (user) => {
  if (!user) return false;
  if (user.role === ROLE_CONST.BoysTownAdmin || user.role === ROLE_CONST.ContentReviewer) return true;
  if (!user.subscriptions || user.subscriptions.length === 0) {
    return false;
  }
  if (user.subscriptions.find((s) => s.product !== 'free' && s.is_active) != null) {
    return true;
  }
};
